import React from 'react';

const About = () => {
  return (
    <div className="about">
      <h1>About Us</h1>
      <p>
        Welcome to <strong>Adishivshakti Enterprises</strong>!
      </p>
      <p>
        At Adishivshakti Enterprises, we pride ourselves on being a premier
        supplier of high-quality spices, herbs, dry fruits, essential oils, and
        herbal extracts. Our journey began with a commitment to bring the finest
        natural products to our customers, sourced directly from the best farms
        and producers around the globe.
      </p>

      <h2>Our Story</h2>
      <p>
        Adishivshakti Enterprises was founded with a vision to provide pure and
        natural ingredients to households and businesses alike. Over the years,
        we have built a reputation for excellence, quality, and reliability in
        the industry. Our dedication to sourcing only the best has made us a
        trusted name in the market.
      </p>

      <h2>What We Offer</h2>
      <ul>
        <li>
          <strong>Spices:</strong> From the vibrant colors of turmeric and
          paprika to the robust flavors of black pepper and cumin, our spices
          are sourced from the finest regions known for their rich soil and
          optimal growing conditions.
        </li>
        <li>
          <strong>Herbs:</strong> We offer a wide range of herbs, both common
          and exotic, that are carefully selected to ensure their potency and
          freshness. Our herbs are perfect for culinary, medicinal, and aromatic
          uses.
        </li>
        <li>
          <strong>Dry Fruits:</strong> Our selection of dry fruits includes the
          finest almonds, cashews, raisins, apricots, and more. These are
          sourced from top producers and are packed with nutrients, providing a
          healthy snack option.
        </li>
        <li>
          <strong>Essential Oils:</strong> We provide a variety of essential
          oils that are 100% pure and natural. Each oil is extracted with
          precision to maintain its therapeutic properties, making them ideal
          for aromatherapy, skincare, and wellness.
        </li>
        <li>
          <strong>Herbal Extracts:</strong> Our herbal extracts are derived from
          high-quality herbs and are processed to preserve their beneficial
          properties. These extracts are perfect for use in supplements,
          cosmetics, and health products.
        </li>
      </ul>

      <h2>Our Mission</h2>
      <p>
        Our mission is to deliver the purest and most authentic natural products
        to our customers. We believe in maintaining the integrity of our
        products through meticulous sourcing, stringent quality control, and
        sustainable practices. Our goal is to enhance the well-being of our
        customers by providing products that are both beneficial and safe.
      </p>

      <h2>Quality Assurance</h2>
      <p>
        At Adishivshakti Enterprises, quality is our top priority. We adhere to
        strict quality control measures at every stage, from sourcing and
        processing to packaging and delivery. Our products undergo rigorous
        testing to ensure they meet the highest standards of purity and potency.
      </p>

      <h2>Sustainability</h2>
      <p>
        We are committed to sustainable practices that not only ensure the
        quality of our products but also protect the environment. By working
        closely with our suppliers and adhering to eco-friendly practices, we
        strive to reduce our ecological footprint and promote sustainability in
        every aspect of our business.
      </p>

      <h2>Customer Commitment</h2>
      <p>
        Customer satisfaction is at the heart of everything we do. We are
        dedicated to providing exceptional service and building long-term
        relationships with our clients. Whether you are a small business, a
        large enterprise, or an individual consumer, we value your trust and
        work tirelessly to meet your needs.
      </p>
      <br />
      <p>
        Thank you for choosing Adishivshakti Enterprises. We look forward to
        serving you with the best nature has to offer!
      </p>
    </div>
  );
};

export default About;
