import Footer from '../components/Footer';
import Header from '../components/Header';

import Spices from '../components/Spices';
import '../styles/Spices.css';

const Spicespage = () => {
  return (
    <>
      <Header />
      <Spices />
      <Footer />
    </>
  );
};
export default Spicespage;
