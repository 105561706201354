/* eslint-disable */
import {FaFacebook, FaInstagram} from 'react-icons/fa';
import {HiOutlineMail} from 'react-icons/hi';
import {MdAddCall} from 'react-icons/md';
import {SlLocationPin} from 'react-icons/sl';
import {SiGooglemaps} from 'react-icons/si';

import '../styles/Footer.css'; // Import your CSS file for styling

const Footer = () => {
  const currentYear = new Date().getFullYear();
  return (
    <div className="footer-container">
      <div className="top-footer">
        <div className="contact">
          <h2>Contact Us</h2>
          <p className="address">Address (India)</p>
          <h4 className="academy-name">Adishivshakti Enterprises</h4>
          <p className="topicondiv">
            <SlLocationPin className="topicons " />
            Mohan Garden Uttam Nagar New Delhi
          </p>
          <p className="topicondiv">
            <MdAddCall className="topicons " />
            +91 9873528930 , +91 9889549759
          </p>
          <p className="topicondiv">
            <HiOutlineMail className="topicons " />
            adishivshaktienterprises@gmail.com
          </p>
        </div>

        <div className="map">
          <h2 className="topicondiv">
            <SiGooglemaps className="icons" />
            Google Map
          </h2>

          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224189.85335623307!2d77.01490775176693!3d28.600783024777403!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390d054d20839c8d%3A0x773298519937a7ca!2sAdishivshakti%20Enterprises!5e0!3m2!1sen!2sin!4v1716844366761!5m2!1sen!2sin"
            width="300"
            height="250"
            title="Adishivshakti Enterprises"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>

      <footer className="footer">
        <div className="social-icons">
          <a href="/">
            <FaFacebook className="icons facebook" />
          </a>
          <a href="/">
            <FaInstagram className="icons instagram" />
          </a>
          <a href=" mailto:adishivshaktienterprises@gmail.com">
            <HiOutlineMail className="icons" />
          </a>
        </div>
        <div className="copyright">
          <p>
            &copy; Copyright 2020-{currentYear} Adishishakti Enterprises.All
            rights reserved.
          </p>
          <p>
            Developed & managed by{' '}
            <a href="https://developinger.in/">Developinger.in</a>
          </p>
        </div>
      </footer>
    </div>
  );
};

export default Footer;
