import React from 'react';
import {BrowserRouter, Routes, Route} from 'react-router-dom';
import Homepage from './pages/homepage';
import Enquirypage from './pages/enquirypage';
import Spicespage from './pages/spicespage';
import SpicesDescriptionpage from './pages/SpicesDescriptionpage';
import Contactpage from './pages/Contactpage';
import ScrollToTop from './components/ScrollToTop';
import Aboutpage from './pages/aboutpage';

function App() {
  return (
    <>
      <BrowserRouter>
        <ScrollToTop>
          <Routes>
            <Route path="/" element={<Homepage />} />
            <Route path="/about" element={<Aboutpage />} />
            <Route path="/enquiry" element={<Enquirypage />} />
            <Route path="/spiceses" element={<Spicespage />} />
            <Route
              path="/spice-description/:id"
              element={<SpicesDescriptionpage />}
            />
            <Route path="/contact" element={<Contactpage />} />
          </Routes>
        </ScrollToTop>
      </BrowserRouter>
    </>
  );
}

export default App;
