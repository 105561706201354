import About from '../components/About';
import Footer from '../components/Footer';
import Header from '../components/Header';
import '../styles/About.css';

const Aboutpage = () => {
  return (
    <>
      <Header />
      <About />
      <Footer />
    </>
  );
};
export default Aboutpage;
