/* eslint-disable */
import {useNavigate} from 'react-router-dom';
import SpiceImage from '../images/spices.jpg'; // Adjust the path based on your project structure

const Home = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className="our-products">
        <h1>Our Product</h1>
        <p>Click on image for more details</p>

        <div className="product-container">
          <div className="product-box">
            <a
              onClick={() => {
                navigate('/spiceses');
              }}
            >
              <img src={SpiceImage} alt="Spices" />
            </a>
            <h3>
              <a
                onClick={() => {
                  navigate('/spiceses');
                }}
              >
                Spices & Herbs
              </a>
            </h3>
            <p>High-quality spices & herbs sourced from around the world.</p>
          </div>
          <div className="product-box">
            <a
              onClick={() => {
                navigate('/');
              }}
            >
              <img
                src="https://media.istockphoto.com/id/653452600/photo/nut-store-in-the-streets-of-fez.jpg?s=612x612&w=0&k=20&c=0Y50ld-Rx3LRSBRrMUzqLM3Hn1iml1Itu_oCETy_4ro="
                alt="Spices"
              />
            </a>
            <h3>
              <a
                onClick={() => {
                  navigate('/');
                }}
              >
                Dry Fruits
              </a>
            </h3>
            <p>High-quality dry fruits sourced from around the world.</p>
          </div>

          <div className="product-box">
            <a
              onClick={() => {
                navigate('/');
              }}
            >
              <img
                src="https://cdn-prod.medicalnewstoday.com/content/images/articles/321/321199/essentials-oils-in-hands.jpg"
                alt="Spices"
              />
            </a>
            <h3>
              <a
                onClick={() => {
                  navigate('/');
                }}
              >
                Essential Oils
              </a>
            </h3>
            <p>High-quality essential oils sourced from around the world.</p>
          </div>
          <div className="product-box">
            <a
              onClick={() => {
                navigate('/');
              }}
            >
              <img
                src="https://5.imimg.com/data5/SELLER/Default/2022/8/NX/PK/QH/124088209/natural-herbal-extract-manufacturers-500x500.jpg"
                alt="Spices"
              />
            </a>
            <h3>
              <a
                onClick={() => {
                  navigate('/');
                }}
              >
                Herbal Extracts
              </a>
            </h3>
            <p>High-quality herbal extracts sourced from around the world.</p>
          </div>

          {/* Add more product boxes here */}
        </div>
      </div>
    </>
  );
};

export default Home;
