import Footer from '../components/Footer';
import Header from '../components/Header';
import SpicesDescription from '../components/SpicesDescription';
import '../styles/SpiceDescription.css';

const SpicesDescriptionpage = () => {
  return (
    <>
      <Header />
      <SpicesDescription />
      <Footer />
    </>
  );
};
export default SpicesDescriptionpage;
