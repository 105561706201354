import React from 'react';
import {HiOutlineMail} from 'react-icons/hi';
import {MdAddCall} from 'react-icons/md';
import {SlLocationPin} from 'react-icons/sl';

const Contact = () => {
  return (
    <div className="contact-container">
      <h1>Contact Us</h1>
      <div className="company-container">
        <h2>Adishivshakti Enterprises</h2>
        <p>Contact Information:</p>

        <ul>
          <p className="topicondiv">
            <MdAddCall className="topicons " />
            +91 9873528930, +91 9889549759
          </p>
          <p className="topicondiv">
            <HiOutlineMail className="topicons " />
            adishivshaktienterprises@gmail.com
          </p>
          <p className="topicondiv">
            <SlLocationPin className="topicons " />
            Mohan Garden, Uttam Nagar, New Delhi
          </p>
        </ul>
      </div>
    </div>
  );
};

export default Contact;
